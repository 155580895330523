import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import _ from 'lodash';
import moment from 'moment';
import "../../../styles/styles.scss";

const SeamlessMonthBreakdown = () => (
  <StaticQuery
    query={graphql`
      query SeamlessMonthBreakdownQuery {
        allSeamlessJson {
          nodes {
            when_for
            id
            charges {
              diner_subtotal
              diner_grand_total
              fees {
                total
              }
              taxes {
                total
              }
              tip {
                amount
              }
            }
          }
        }  
      }
    `}
    render={data => {

      const orders = data.allSeamlessJson.nodes;

      const ordersByMonth = _.groupBy(orders, (order) => 
        moment(order.when_for).startOf('month').format('MMM Y')
      );

      const chartData = _.reverse(
        Object.entries(ordersByMonth).map(([month, orders]) => {
          let total = 0;
          let food = 0;
          let tips = 0;
          let fees = 0;
          let taxes = 0;

          for (const order of orders) {
            total += order.charges.diner_grand_total;
            food += order.charges.diner_subtotal;
            tips += order.charges.tip.amount;
            fees += order.charges.fees.total;
            taxes += order.charges.taxes.total;
          }

          return {
            month,
            total: total / 100,
            food: food / 100,
            tips: tips / 100,
            fees: fees / 100,
            taxes: taxes / 100,
          }
        })
      );

      return (
        <div style={{ margin: '-20px', height: '500px' }}>
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              height={500}
              data={chartData}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="total" fill="#FF5630"/>
              <Bar dataKey="food" fill="#FFAB00" stackId="breakdown" />
              <Bar dataKey="tips" fill="#0065FF" stackId="breakdown" />
              <Bar dataKey="fees" fill="#00B8D9" stackId="breakdown" />
              <Bar dataKey="taxes" fill="#36B37E" stackId="breakdown" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      );
    }}
  />
)

export default SeamlessMonthBreakdown
